import { useMemo } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { color, percent } from "csx"
import { Box, styled, VehicleImage } from "@tm/components"
import { VehicleType } from "@tm/models"
import { Icon } from "@tm/controls"
import { FastCalculator } from "@tm/data"
import { getLastModelDetails, saveVehicleToWorkTask } from "../../../data/helpers"
import { Milestone, SummaryContext } from "../../../data/model"
import { useFastCalculatorStore } from "../../../state"
import { BorderedCollapsible, CalcInputComponent } from "../../_shared"

export default function VehicleDefinition() {
    const workTask = useWorkTask()
    const context = useFastCalculatorStore((state) => state.main.selectedCalcState?.context as SummaryContext | undefined)
    const vehicle = useFastCalculatorStore((state) => state.main.vehicle)
    const carModel = useFastCalculatorStore((state) => state.main.carModel ?? getLastModelDetails())

    const { defineVehicleLabel, defineVehicleDescription, inputs, motivationGraphic } = useMemo(
        () => ({
            defineVehicleLabel: context?.defineVehicleLabel ?? "",
            defineVehicleDescription: context?.defineVehicleDescription ?? "",
            inputs: context?.inputs ?? [],
            motivationGraphic: context?.motivationGraphic,
        }),
        [context]
    )

    function renderMilestone(milestone: Milestone, index: number) {
        return (
            <StyledMilestone key={index} style={{ left: percent(milestone.positionPercent) }}>
                <Icon skin={(milestone.reached && "primary") || undefined} size="l" name="raceflag" />
                {milestone.label}
            </StyledMilestone>
        )
    }

    const onChangeCallBack = (data: string | Date | number | boolean) => {
        saveVehicleToWorkTask(data, workTask?.workTask?.vehicle, workTask?.attachToWorkTask)
    }

    return (
        <BorderedCollapsible name={defineVehicleLabel} initiallyOpened>
            <Box margin="auto" maxWidth="60em" display="flex" flexDirection="column">
                <StyledVehicleSlider>
                    <StyledVehicleImage
                        modelImage={vehicle?.modelThumbnail || ""}
                        modelSeriesImage={vehicle?.modelSeriesThumbnail || ""}
                        vehicleType={vehicle?.vehicleType || VehicleType.PassengerCar}
                        reachedPercent={motivationGraphic?.reachedPercent}
                    />

                    {motivationGraphic?.milestones.map(renderMilestone)}

                    <StyledSlider>
                        <StyledCompleteSlider reachedPercent={motivationGraphic?.reachedPercent} />
                    </StyledSlider>
                </StyledVehicleSlider>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    {inputs.map((input) => (
                        <CalcInputComponent
                            item={input}
                            key={input.id}
                            onChangeCallBack={onChangeCallBack}
                            showBorder={input.style != FastCalculator.ECalcInputStyle.Slider}
                            modelDetails={carModel?.modelDetails}
                        />
                    ))}
                </Box>
            </Box>
        </BorderedCollapsible>
    )
}

const StyledVehicleSlider = styled(Box)({
    position: "relative",
    marginBottom: "1em",
    height: "3em",
})

const StyledVehicleImage = styled(VehicleImage)<{ reachedPercent?: number }>(({ reachedPercent, theme }) => ({
    position: "absolute",
    left: percent(reachedPercent ?? 0),
    top: "1em",
    height: "1.5em",
    $nest: {
        "&.error-image": {
            top: "-0.1em",
            $nest: {
                svg: {
                    width: "4em",
                    height: "4em",
                },
            },
        },
    },
}))

const StyledSlider = styled(Box)(({ theme }) => ({
    backgroundColor: color(theme.colors?.warning ?? "#ffeb3b")
        .mix(theme.colors?.danger ?? "#f44336")
        .toString(),
    height: "0.4em",
    marginTop: "2.5em",
}))

const StyledCompleteSlider = styled(Box)<{ reachedPercent?: number }>(({ reachedPercent, theme }) => ({
    backgroundColor: theme.colors?.success,
    height: "100%",
    transition: "width .3s ease",
    width: percent((reachedPercent ?? 0) > 90 ? 100 : reachedPercent || 0),
}))

const StyledMilestone = styled(Box)(({ theme }) => ({
    position: "absolute",
    display: "flex",
    opacity: theme.opacity?.disabled,
}))
