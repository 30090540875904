import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Box, Modal, useMediaQuery, useTheme } from "@tm/components"
import { FastCalculator } from "@tm/data"
import { getLabourRate } from "../../../data/helpers"
import Content from "./shared/Content"
import { useBasketImports } from "../../../../../basket/src/hooks/basketState/useBasketImports"
import { useFastCalculatorStore } from "../../../state"
import { handleButtonClick } from "../../../business"

export default function SelectionModal() {
    const muitheme = useTheme()
    const matchesLg = useMediaQuery(muitheme.breakpoints.up("lg"))
    const matchesXl = useMediaQuery(muitheme.breakpoints.up("xl"))
    const loading = useFastCalculatorStore((state) => state.main.loading)
    const selectedOverlayCalcState = useFastCalculatorStore((state) => state.main.selectedOverlayCalcState)
    const { userSettings } = useUser() ?? {}
    const { languageId } = useLocalization()
    const { importFastCalculation } = useBasketImports()

    // eslint-disable-next-line no-nested-ternary
    const modalWidth = useMemo(() => (matchesXl ? 55 : matchesLg ? 70 : 85), [matchesLg, matchesXl])

    const handleCloseModal = () => {
        const closeBtn = selectedOverlayCalcState?.buttons?.find((x) => x.type === FastCalculator.ECalcButtonState.Close)
        closeBtn && handleButtonClick(closeBtn, languageId, importFastCalculation, getLabourRate(userSettings))
    }

    return (
        <Modal
            keepMounted
            onOutsideClick={handleCloseModal}
            open
            percentWidth={modalWidth}
            sx={{ "&&  .MuiPaper-root>.MuiBox-root": { flex: 1, display: "flex" } }}
        >
            <Box marginLeft="2em" width="100%">
                <Content calcState={selectedOverlayCalcState} loading={loading} onClose={handleCloseModal} />
            </Box>
        </Modal>
    )
}
