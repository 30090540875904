import { History } from "history"
import { Article, OE } from "@tm/models"
import { UserSettings } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { FastCalculator } from "@tm/data"
import { Services, AlternativeCalcStateContext } from "../../../data/model"
import { getLabourRate, getProductGroupIds, getTopProductGroups } from "../../../data/helpers"

import { navigateWithReplaceParam } from "../../../helpers"
import { mainActions, useFastCalculatorStore } from "../../../state"
import { handleAddPartFromArticleList, handleInputSubmit, initFastCalculator, startCalculationWithProductGroups } from "../../../business"

export function handleChecboxToggles(validServices: Services, newService: FastCalculator.CalcSelectionItem, existsInWS?: boolean) {
    if (existsInWS && newService.isSelected) {
        // case where we uncheck the one that is checked on the server
        // we need to add it to a unsavedServices list so it will toggle on the server
        validServices.unsavedServices.push(newService)

        // remove it from selectedServices so the button will go disbaled if needed
        validServices.selectedServices = validServices.selectedServices.filter((s) => s.id !== newService.id)
    } else if (existsInWS && !newService.isSelected) {
        // case where we check the one that is checked on the server
        // we need to remove it from the unsavedServices list so it will not toggle on the server
        validServices.unsavedServices = validServices.unsavedServices.filter((s) => s.id !== newService.id)

        // add it back to the selectedServices so the button will go enabled
        validServices.selectedServices.push(newService)
    } else if (newService.isSelected) {
        // case where we uncheck one that is NOT YET on the server
        // remove from the unsavedServices list and from the selectedService to disable button
        validServices.unsavedServices = validServices.unsavedServices.filter((s) => s.id !== newService.id)
        validServices.selectedServices = validServices.selectedServices.filter((s) => s.id !== newService.id)
    } else if (!newService.isSelected) {
        // case where we check one that is NOT YET on the server
        // add itt to the ewlySelected list and selectedService to enable button
        validServices.unsavedServices.push(newService)
        validServices.selectedServices.push(newService)
    }
}

export function replaceArticleCancel({
    history,
    languageId,
    userSettings,
}: {
    history: History<unknown>
    languageId: string
    userSettings: UserSettings | undefined
}) {
    const { replaceArticle, inModal } = useFastCalculatorStore.getState().main

    // this is needed because parent transit redux dispatch enters for each component (main, productGroups) and not to fire twice
    // inModal = undefined = uninitialized, false - main FC, true - productGroups FC in modal
    // replaceArticle is not undefined => replace in progress, undefined -> wrong component or refresh
    if (!replaceArticle && typeof inModal === "undefined") {
        return false
    }

    // this is case of refresh
    if (!replaceArticle) {
        mainActions.resetArticleReplace()
        Morpheus.getHistory().goBack()
        return
    }

    navigateWithReplaceParam(history, replaceArticle, true)

    const productGroupIds = getProductGroupIds(replaceArticle.match?.params?.productGroupIds)

    if (productGroupIds) {
        const topProductGroups: FastCalculator.TopProductGroup[] | undefined = getTopProductGroups(replaceArticle.match?.params?.productGroupIds)

        startCalculationWithProductGroups(
            productGroupIds,
            languageId,
            getLabourRate(userSettings),
            replaceArticle.match?.params?.origin,
            topProductGroups,
            () => {
                mainActions.resetArticleReplace()
                navigateWithReplaceParam(history, replaceArticle, false)
            }
        )

        return
    }

    initFastCalculator(false, undefined, undefined, () => {
        mainActions.resetArticleReplace()
        navigateWithReplaceParam(history, replaceArticle, false)
    })
}

export function replaceArticleFinish({ replacePart, history }: { replacePart: Article | OE.OePart; history: History<unknown> }) {
    const { alternativeCalcArticles, replaceArticle, inModal } = useFastCalculatorStore.getState().main

    // this is needed because parent transit redux dispatch enters for each component (main, productGroups) and not to fire twice
    // inModal = undefined = uninitialized, false - main FC, true - productGroups FC in modal
    // replaceArticle is not undefined => replace in progress, undefined -> wrong component or refresh

    if (!replaceArticle || typeof inModal === "undefined") {
        return false
    }

    const alternativesContext = alternativeCalcArticles?.context as AlternativeCalcStateContext

    // If the replacement part is the same as the chosen part do nothing
    if (replaceArticle.part?.id == replacePart.id) {
        return
    }

    mainActions.resetArticleReplace()

    navigateWithReplaceParam(history, replaceArticle, true)

    //  If the replacement part is a suggestion of the Fast Calculator submit the replace input instead
    const alternative = alternativesContext?.articles?.find((x) => x.id == replacePart.id)
    if (alternative?.replaceInput) {
        handleInputSubmit(alternative.replaceInput, () => {
            navigateWithReplaceParam(history, replaceArticle, false)
        })

        return
    }

    replacePart = replacePart as Article

    if (replacePart.supplierArticleNo && replacePart.productGroup?.id && replacePart.internalId && replacePart.supplier?.id) {
        handleAddPartFromArticleList(
            {
                eArtNr: replacePart.supplierArticleNo,
                genArtNr: replacePart.productGroup.id,
                iArtNr: replacePart.internalId,
                supplierId: replacePart.supplier.id,
                genArtId: replaceArticle.genArt.id,
                oeGroupId: (replaceArticle.part as any)?.oeId, // TODO missing type ?
                partId: replaceArticle.part?.id,
            },
            FastCalculator.ESearchLink.Direct,
            () => {
                navigateWithReplaceParam(history, replaceArticle, false)
            }
        )
    }
    // else {
    //     actions.handleAddPartFromArticleList(({
    //         eArtNr: (replacePart as any).eArtNr,
    //         genArtNr: genArt.genArtNr,
    //         iArtNr: (replacePart as any).iArtNr,

    //         supplierId: (replacePart as any).manufacturerId,
    //         oeGroupId: part?.oeId,
    //         partId: part?.id
    //     }) as any, ESearchLink.Direct)
    // }
}
