import { useStyle, useUser } from "@tm/context-distribution"
import { Table, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { em, rem } from "csx"
import { repairTimeSelector } from "../../../data/helpers"
import { getCurrencyFromUserContext } from "../../../data/helpers/userSettingsContextMethods"
import { CalcRepairTime } from "../../../data/model"
import { BorderedCollapsible, CalcInputComponent, CalcInputNumber } from "../../_shared"

const WorkListComponent = () => {
    const { currency, translateText } = useLocalization()
    const userContext = useUser()?.userContext
    const { globalHourPrice, repairTimes, label } = repairTimeSelector() || {}

    const renderProviderId = (work: CalcRepairTime) => {
        return (
            <Table.Cell className={style.number}>
                <Text>{work.repairTimeId}</Text>
            </Table.Cell>
        )
    }

    const renderDescription = (work: CalcRepairTime) => {
        return (
            <Table.Cell className={style.description}>
                <Text>{work.label} </Text>
            </Table.Cell>
        )
    }

    const renderRtValue = (work: CalcRepairTime) => {
        const currencyString = getCurrencyFromUserContext(userContext)
        return (
            <Table.Cell className={style.value}>
                <Text modifiers="strong">{currency(work.totalPrice, currencyString)}</Text>
            </Table.Cell>
        )
    }

    const renderQuantity = (work: CalcRepairTime) => {
        return (
            <Table.Cell className={style.quantity}>
                <CalcInputComponent item={work.quantity} />
            </Table.Cell>
        )
    }

    const renderRemoveBtn = (work: CalcRepairTime) => {
        return (
            <Table.Cell className={style.deleteBtn}>
                <CalcInputComponent item={work.removeInput} />
            </Table.Cell>
        )
    }

    const renderWorkItemPrice = (work: CalcRepairTime) => {
        return (
            <Table.Cell className={style.value}>
                <CalcInputNumber item={work.price} />
            </Table.Cell>
        )
    }

    if (!repairTimes || !globalHourPrice) {
        return null
    }

    return (
        <BorderedCollapsible name={label ?? ""} className={style.works} initiallyOpened>
            <CalcInputComponent item={globalHourPrice} className={style.globalHourRate} />
            <Table
                data={repairTimes}
                columns={[
                    <Table.Column className={style.number} renderItemContent={renderProviderId}>
                        {translateText(58)}
                    </Table.Column>,
                    <Table.Column className={style.description} renderItemContent={renderDescription}>
                        {translateText(617)}
                    </Table.Column>,
                    <Table.Column className={style.value} renderItemContent={renderWorkItemPrice}>
                        {`${translateText(1114)} ${translateText(621)}`}
                    </Table.Column>,
                    <Table.Column className={style.value} renderItemContent={renderRtValue}>
                        {`${translateText(621)} ${translateText(85)}`}
                    </Table.Column>,
                    <Table.Column className={style.quantity} renderItemContent={renderQuantity}>
                        {translateText(89)}
                    </Table.Column>,
                    <Table.Column className={style.deleteBtn} renderItemContent={renderRemoveBtn}>
                        {translateText(69)}
                    </Table.Column>,
                ]}
            />
        </BorderedCollapsible>
    )
}

const style = useStyle({
    works: {
        marginTop: em(1),
        $nest: {
            ".collapsible__content": {
                display: "flex",
                flexDirection: "column",
            },
        },
    },
    number: {
        flexBasis: rem(10),
    },
    description: {
        flex: 1,
    },
    value: {
        flexBasis: rem(8),
        $nest: {
            "&::first-letter": {
                textTransform: "uppercase",
            },
        },
    },
    quantity: {
        flexBasis: rem(6),
    },
    deleteBtn: {
        flexBasis: rem(2),
        textTransform: "capitalize",
    },
    globalHourRate: {
        maxWidth: rem(10),
        alignSelf: "flex-end",
        marginRight: "10em",
    },
})(WorkListComponent)

export default WorkListComponent
