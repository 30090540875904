import { ECalcResult } from "../enums"
import { CalculationContext, SummaryContext } from "../model"

import { useFastCalculatorStore } from "../../state"

export const contextSelector = () => {
    const context = useFastCalculatorStore.getState().main.selectedCalcState?.context as CalculationContext | undefined
    const currencySign = context?.wkz ?? "EUR"
    const hasInvalidArticles = context?.articles?.genArts?.some(
        (x) => (!x.articles && !x.price.value) || x.articles?.some((y) => y.priceEditable && !y.price.value) || !x.quantity.value
    )
    const hasInvalidRepairTimes = context?.repairTimes?.repairTimes?.some((x) => x.quantity.isInvalid)
    const hasInvalidConsumabled = context?.consumables?.consumables.some((x) => !x.price.value || !x.quantity.value)
    return {
        currencySign,
        hasInvalidArticles,
        hasInvalidRepairTimes,
        hasInvalidConsumabled,
    }
}

export const deepResultSelector = () => {
    const calcResults = (useFastCalculatorStore.getState().main.selectedCalcState?.context as CalculationContext)?.results
    const articlePrice = calcResults?.find((x) => x.type == ECalcResult.Articles)?.totalPrices[0]
    const repairTimesPrice = calcResults?.find((x) => x.type == ECalcResult.RepairTimes)?.totalPrices[0]
    const totalPrice = calcResults?.find((x) => x.type == ECalcResult.Total)?.totalPrices[0]
    const totalPriceVat = calcResults?.find((x) => x.type == ECalcResult.Total)?.totalPrices[1]
    return { articlePrice, repairTimesPrice, totalPrice, totalPriceVat }
}

export const repairTimeSelector = () => {
    const context = useFastCalculatorStore.getState().main.selectedCalcState?.context
    return (context as CalculationContext)?.repairTimes
}

export const summarySelector = () => {
    const context = useFastCalculatorStore.getState().main.selectedCalcState?.context

    return context as SummaryContext
}
