import { Box, Button, Divider, Icon, Loader, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect } from "react"
import { useGetBookingDetails } from "../../../hooks/useGetBookingDetails"
import NoResultHint from "../../_shared/NoResult"
import DetailsHeader from "./DetailsHeader"
import CompactBookingsList from "./CompactBookingsList"
import { mainActions, summaryActions, useDrivemotiveStore } from "../../../data/state"
import CalculationDetails from "./CalculationDetails"
import VerticalRule from "../../_shared/VerticalRule"
import { NavigationSteps } from "../../../data/models"

export default function BookingsDetails() {
    const { translateText } = useLocalization()
    const selectedBooking = useDrivemotiveStore((state) => state.summary.selectedBooking)
    const selectedCalculation = useDrivemotiveStore((state) => state.summary.selectedArticleAndWork)

    if (!selectedBooking) {
        return <NoResultHint />
    }

    const { bookingDetails, isLoading } = useGetBookingDetails(selectedBooking?.id)

    if (!bookingDetails && !isLoading) {
        return <NoResultHint />
    }

    useEffect(() => {
        if (bookingDetails?.calculations) {
            summaryActions.setCalculationArticlesAndWorks(bookingDetails?.calculations)
        } else {
            summaryActions.setCalculationArticlesAndWorks(undefined)
        }
    }, [selectedBooking?.id, bookingDetails, isLoading])

    const onClick = () => {
        mainActions.changeStep(NavigationSteps.Calculation)
        mainActions.makeCalculationTabAvailable()
    }

    return (
        <>
            <StyledCalculationButton
                color="highlight"
                size="extralarge"
                endIcon={<Icon name="arrow-right" />}
                disabled={!Object.keys(selectedCalculation).length}
                onClick={onClick}
            >
                {translateText(13000)}
            </StyledCalculationButton>
            <Box sx={{ display: "flex" }}>
                <CompactBookingsList />
                <VerticalRule />
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    {isLoading ? (
                        <StyledLoader />
                    ) : (
                        <>
                            <DetailsHeader />
                            <Divider sx={{ margin: "1em 0" }} />
                            <CalculationDetails />
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

const StyledLoader = styled(Loader)({
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1,
})

const StyledCalculationButton = styled(Button)({
    alignSelf: "end",
    zIndex: 1,
    marginTop: "-3em",
})
