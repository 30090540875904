import { useLocalization } from "@tm/localization"
import { Accordion, AccordionDetails, AccordionSummary, Box, ExpandMore, Radio, Typography } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useEffect, useState } from "react"
import SpareParts from "./SpareParts"
import SummaryWorks from "./SummaryWorks"
import { summaryActions, useDrivemotiveStore } from "../../../data/state"
import { CalculationsMapped } from "../../../data/models/calculation"

export default function CalculationDetails() {
    const { translateText, currency } = useLocalization()
    const calculations = useDrivemotiveStore((state) => state.summary.calculations)
    const [selectedCalculation, setSelectedCalculation] = useState(calculations?.[0])

    useEffect(() => {
        if (calculations && calculations.length > 0) {
            setSelectedCalculation(calculations[0])
            summaryActions.setSelectedArticleAndWork(calculations[0])
        }
    }, [calculations])

    const handleRadioChange = (calculation: CalculationsMapped) => {
        setSelectedCalculation(calculation)
        summaryActions.setSelectedArticleAndWork(calculation)
    }

    return (
        <>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", paddingRight: "1em" }}>
                <Typography variant="h2">{translateText(13696)}</Typography>
                {/* Will be needed later */}
                {/* <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {translateText(13661)}
                    <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(totalPrice, currencyString)}</Box>
                </Box> */}
            </Box>
            {calculations?.map((calculation) => (
                <Accordion sx={{ mb: ".3em" }} key={calculation.title} disableGutters>
                    <AccordionSummary sx={{ ml: "1em" }}>
                        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                            <Typography>{calculation.title}</Typography>
                            <Radio
                                style={{ transform: "scale(1.8)", marginRight: "1em", backgroundColor: "transparent" }}
                                disableRipple
                                checked={selectedCalculation === calculation}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleRadioChange(calculation)
                                }}
                            />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {calculation.summaryArticles && <SpareParts articles={calculation.summaryArticles} />}
                        {calculation.totalPriceWorks && (
                            <SummaryWorks summaryWorks={calculation.summaryWorks} totalPriceWorks={calculation.totalPriceWorks} />
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}
