import { FC } from "react"
import { Box, Button, Icon, LinkButton, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { FastCalculator } from "@tm/data"
import { CalcStateButton } from "../_shared"
import { getLabourRate } from "../../data/helpers"
import { useBasketImports } from "../../../../basket/src/hooks/basketState/useBasketImports"
import { queueActions } from "../../state"
import { handleButtonClick } from "../../business"

type Props = {
    isQueued: boolean
    isError: boolean
    resetBtn?: FastCalculator.CalcStateButton
    workTaskId: string
    isSmall?: boolean
}
const StyledIcon = styled(Icon)({
    // width: "max-content",
    height: "10rem",
    margin: "5em auto 1em auto",
    width: "auto",
    flex: 1,
})
const QueuedStatusComponent: FC<Props> = (props) => {
    const { isError, isQueued, resetBtn, workTaskId, isSmall } = props
    const { translateText, languageId } = useLocalization()
    const dashboard = `/${encodeUniqueId(workTaskId)}`

    const { userSettings } = useUser() || {}
    const { importFastCalculation } = useBasketImports()

    const handleCancelQueue = () => {
        queueActions.queueCancelled()
        resetBtn &&
            handleButtonClick(
                resetBtn,
                languageId,
                importFastCalculation,
                getLabourRate(userSettings),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
            )
    }

    if (isError) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" rowGap={isSmall ? "0" : "1em"}>
                {!isSmall && <StyledIcon name="car-front" path="car-front" />}
                <Typography variant={isSmall ? "body3" : "h3"} fontWeight="bold">
                    {translateText(13334)}
                </Typography>
                <Typography marginBottom={isSmall ? "0" : "2em"}>
                    {translateText(13331)} <br />
                    {translateText(13332)}
                </Typography>
                <Typography>{translateText(13333)}</Typography>
                {(resetBtn && <CalcStateButton item={resetBtn} />) || translateText(13340)}
                {!isSmall && <LinkButton color="highlight" value={translateText(13339)} variant="contained" to={dashboard} />}
            </Box>
        )
    }
    if (isQueued) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" rowGap={isSmall ? "0.5em" : "1em"}>
                {!isSmall && <StyledIcon name="time" path="time" />}
                <Typography variant={isSmall ? "h4" : "h3"} fontWeight="bold">
                    {translateText(13335)}
                </Typography>
                <Typography marginBottom="2em" textAlign="center">
                    {translateText(13348)}
                </Typography>
                <Typography variant={isSmall ? "body2" : "body1"} marginBottom="0.5em">
                    {translateText(13343)}
                </Typography>
                <Box display="flex" columnGap="1em">
                    <Button variant="contained" onClick={handleCancelQueue}>
                        {translateText(13338)}
                    </Button>
                    {/* {!isSmall && <LinkButton color="highlight" value={translateText(13339)} variant="contained" to={dashboard} />} */}
                </Box>
            </Box>
        )
    }
    return null
}
export default QueuedStatusComponent
