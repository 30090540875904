import { Select, FormControl, InputLabel, ListSubheader, MenuItem, makeStyles } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { FastCalculator } from "@tm/data"
import { ArticleInput, DropDownItems } from "../../../data/model"
import { handleDropdownItemClick } from "../../../business"

type Props = {
    item: ArticleInput
    genArtLabel: string
}

const ArticleInputComp = ({ item, genArtLabel }: Props) => {
    const { translateText } = useLocalization()

    const selectedItem = item.dropDownItems.find((x) => x.isSelected)

    const styles = useStyle()

    const handleChange = (dropdownItem: DropDownItems) => {
        handleDropdownItemClick(item as unknown as FastCalculator.CalcInput, dropdownItem as unknown as FastCalculator.CalcDropDownItem)
    }
    return (
        <FormControl className={styles.fullSpace} fullWidth>
            <InputLabel id="calc_consumables_article">{genArtLabel}</InputLabel>
            <Select
                labelId="calc_consumables_article"
                id="demo-simple-select"
                value={selectedItem?.label ?? ""}
                defaultValue=""
                label={genArtLabel}
                variant="outlined"
                className={styles.inputText}
            >
                <div className={styles.headerDropDown}>
                    <ListSubheader className={styles.subText}>{translateText(87)}</ListSubheader>
                    <ListSubheader className={styles.subText}>{translateText(25)}</ListSubheader>
                    <ListSubheader className={styles.subText}>{translateText(56)}</ListSubheader>
                </div>
                {item.dropDownItems.map((item, key) => (
                    <MenuItem
                        onClick={() => {
                            handleChange(item)
                        }}
                        className={styles.headerDropDown}
                        key={key}
                        value={item.label}
                    >
                        {item.labelColumns.map((label, key) => (
                            <div key={key}>{label}</div>
                        ))}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ArticleInputComp

const useStyle = makeStyles({
    inputText: {
        height: "100%",
        "&& .MuiSelect-select": {
            display: "flex",
            gap: ".5em",
        },
    },
    headerDropDown: {
        display: "grid",
        gridAutoColumns: "minmax(0, 1fr)",
        gridAutoFlow: "column",
        "&&:not(.MuiMenuItem-root)": {
            padding: "0 16px",
        },
    },
    fullSpace: {
        height: "100%",
    },
    subText: {
        padding: "0",
        lineHeight: "24px",
        fontSize: ".85rem",
    },
})
