import { MouseEvent, useEffect, useMemo, useState } from "react"
import { Box, Icon, styled, Tooltip, Typography } from "@tm/components"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Checkbox, Table, Text } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { FastCalculator } from "@tm/data"
import { getCalcStateIcon } from "../../data/helpers"
import CalcInputCheck from "./calcInputs/calcInputCheck"
import { handleSelectionItemClick } from "../../business"

type Props = {
    items: FastCalculator.CalcSelectionItem[]
    disableNewSelection?: boolean
    isOverlayCalcState?: boolean
    isFromDialog?: boolean
    handleLonglifeSwitch?: (data: boolean) => void
}

export default function SelectionItems(props: Props) {
    const { disableNewSelection } = props
    const style = useMemo(() => getStyle(), [])
    const [items, setItems] = useState<FastCalculator.CalcSelectionItem[]>([])

    useEffect(() => {
        setItems([...props.items])
    }, [props.items])

    function handleSecondaryClickRow(e: MouseEvent) {
        e && e.stopPropagation()
    }

    function renderRow(item: FastCalculator.CalcSelectionItem) {
        return (
            <Table.Cell>
                <StyledRowItem>
                    <Text>
                        {item.label}
                        {item?.selectionReason && (
                            <Tooltip title={item?.selectionReason}>
                                <StyledSelectedItemIcon name="info-filled" />
                            </Tooltip>
                        )}
                    </Text>

                    <Checkbox checked={item.isSelected} disabled={!item.isSelected && disableNewSelection} />
                </StyledRowItem>
                {item.subInputs && (
                    <StyledSecondaryRowBox>
                        {item.subInputs.map((subInput) => {
                            const icon = getCalcStateIcon(subInput.icon)

                            return (
                                <StyledSecondaryRowItem onClick={handleSecondaryClickRow} key={subInput.id}>
                                    {icon && <Icon name={icon} />}
                                    {subInput.title && <Typography variant="label">{subInput.title}</Typography>}

                                    <CalcInputCheck disableTitleLabel key={subInput.id} item={subInput} />
                                </StyledSecondaryRowItem>
                            )
                        })}
                    </StyledSecondaryRowBox>
                )}
            </Table.Cell>
        )
    }

    function handleCheck(item: FastCalculator.CalcSelectionItem) {
        if (!item.isSelected && disableNewSelection) {
            return
        }

        const { isOverlayCalcState, isFromDialog } = props
        setItems((oldItems) =>
            oldItems.map(
                (x) =>
                    (x.id == item.id && {
                        ...x,
                        isSelected: !item.isSelected,
                    }) ||
                    x
            )
        )

        handleSelectionItemClick(item, isOverlayCalcState, isFromDialog)

        if (item.properties && item.properties[0]?.type === FastCalculator.EPropertyType.IsLonglife) {
            props.handleLonglifeSwitch?.(item.properties[0].value === "True")
        }
    }

    return (
        <Box className={style.wrapper}>
            <Table
                data={items}
                onClickRow={handleCheck}
                columns={[<Table.Column className={style.fullWidth} renderItemContent={renderRow} />]}
                getRowClassName={(data) => `models__item ${data.isSelected ? "is-selected" : ""}`}
            />
        </Box>
    )
}

const StyledRowItem = styled("div")({
    display: "flex",
    justifyContent: "space-between",
})

const StyledSecondaryRowBox = styled("div")({
    borderTop: "1px solid rgb(226, 226, 226)",
    marginTop: "0.5em",
    paddingTop: "0.5em",
})

const StyledSecondaryRowItem = styled("div")({
    display: "flex",
    gap: "0.8em",
    alignItems: "center",
})

const StyledSelectedItemIcon = styled(Icon)({
    verticalAlign: "middle",
    marginLeft: "1em",
})

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            $nest: {
                ".fancy-list__item": {
                    justifyContent: "space-between",
                    cursor: "pointer",
                },
                ".checkbox": {
                    opacity: theme.opacity.primary,
                },
                ".checkbox__icon": {
                    opacity: theme.opacity.primary,
                },
                ".checkbox--disabled": {
                    opacity: 0.38,
                },
            },
        },
        fullWidth: {
            width: "100%",
        },
    })(SelectionItems)
}
