import { AddOePartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { reloadOeArticleQuantities } from "../../.."
import { useBasketModuleParameter } from "../../../../hooks/useBasketModuleParameter"
import { useCreateSendArticlesPostMessage } from "../../../../hooks/useCreateSendArticlesPostMessage"
import { useHandlePostSendOrderAction } from "../../../../components/_shared/order-button/hooks/useHandlePostSendOrderAction"

export function useAddOePartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const basketModule = useBasketModuleParameter()
    const { getMessageByOePart } = useCreateSendArticlesPostMessage()
    const handlePostSendOrderAction = useHandlePostSendOrderAction()
    const { mutateAsync: addOePartList } = useMutation((addWOePartListRequest: AddOePartListRequest) => Data.addOePartList(addWOePartListRequest), {
        onSuccess: (response, request) => {
            if (response) {
                handleBasketUpdateWorkflow(request.workTaskId, response)
            }

            // Save work task in the DB
            Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()

            // The Add to basket buttons require the quantites added in the basket for the badge
            request.oeParts.forEach((article) => {
                if (article.oeArticleNumber) {
                    reloadOeArticleQuantities(request.workTaskId, article)
                }
            })
        },
    })
    const addOeParts = useCallback(
        (request: AddOePartListRequest) => {
            if (basketModule.sendPostMessage) {
                if (basketModule.sendPostMessage) {
                    const message = getMessageByOePart(request.oeParts, request.workTaskId)
                    handlePostSendOrderAction(request.workTaskId, message)
                }
            }
            if (basketModule?.addToNextBasket !== false) {
                return addOePartList(request)
            }
            return Promise.reject(new Error("no function"))
        },
        [basketModule?.addToNextBasket, basketModule.sendPostMessage, getMessageByOePart]
    )

    return addOeParts
}
