import { atom, useRecoilState, useRecoilValue } from "recoil"
import { Article, RecommendedArticles, Vehicle } from "@tm/models"
import { useCallback, useMemo, useState } from "react"
import { usePureArticleLists, useWorkTask } from "@tm/context-distribution"
import { Articles } from "@tm/data"
import { useQueryClient } from "react-query"
import { PureListComponent } from "../../../PureList/component"
import { createRecommendedArticlesRequest } from "./createRecommendedArticlesRequest"
import { getCachedWorkTaskBasketData } from "../../../../../../basket/src/data/hooks/workTaskBasket/queries/useWorkTaskBasketData"
import { PartsViewSettingsState } from "../../states"

const RecommendedArticleIdState = atom<string | undefined>({
    key: "parts_recommendedArticles",
    default: undefined,
})

export function useRecommendedArticles(
    article: Article,
    enableRecommendedArticles: boolean,
    isVehicleDependent: boolean,
    vehicle?: Vehicle
): RecommendedArticles {
    const listId = "recommended-articles"
    const queryClient = useQueryClient()

    const workTaskId = useWorkTask()?.workTaskId!

    const partsViewSettings = useRecoilValue(PartsViewSettingsState)
    const quantitySuggestionEnabled = useMemo(() => partsViewSettings.quantitySuggestionEnabled, [partsViewSettings?.quantitySuggestionEnabled])

    const { reset, setRequest } = usePureArticleLists()
    const [lastArticleAddedToBasket, setLastArticleAddedToBasket] = useRecoilState(RecommendedArticleIdState)

    const [isLoading, setIsLoading] = useState(false)

    const enabled = useMemo(
        () => enableRecommendedArticles && isVehicleDependent && lastArticleAddedToBasket === article.id,
        [enableRecommendedArticles, isVehicleDependent, lastArticleAddedToBasket, article.id]
    )

    const loadArticles = useCallback(
        async (newArticle: Article) => {
            // get the data directly async without mutating the state and do rerender hell
            const workTaskBasket = await getCachedWorkTaskBasketData(queryClient, workTaskId, true, false, false)

            const request = createRecommendedArticlesRequest(workTaskBasket?.parts, newArticle, vehicle!)

            if (!request) {
                return undefined
            }

            const recommendedArticlesOut = await queryClient.fetchQuery({
                queryKey: [listId, request],
                queryFn: () => Articles.getRecommendedArticles(request),
            })

            if (!quantitySuggestionEnabled || !recommendedArticlesOut?.length) {
                return recommendedArticlesOut
            }

            return recommendedArticlesOut.map((recommendedArticle) => {
                if (!recommendedArticle.suggestedQuantity) {
                    return recommendedArticle
                }

                return {
                    ...recommendedArticle,
                    quantity: recommendedArticle.suggestedQuantity,
                    initialQuantity: recommendedArticle.suggestedQuantity,
                }
            })
        },
        [queryClient, vehicle, workTaskId, quantitySuggestionEnabled]
    )

    const loadRecommendedArticles = useCallback(async () => {
        if (!enableRecommendedArticles || !isVehicleDependent || !vehicle) {
            return
        }

        setLastArticleAddedToBasket(undefined)

        const recommendedArticlesOut = await loadArticles(article)

        if (recommendedArticlesOut?.length) {
            setRequest(listId, { articles: recommendedArticlesOut })
            setLastArticleAddedToBasket(article.id)
        }
    }, [enableRecommendedArticles, isVehicleDependent, vehicle, setLastArticleAddedToBasket, loadArticles, article, setRequest])

    const loadNextRecommendedArticle = useCallback(
        async (newArticle: Article) => {
            if (!enableRecommendedArticles || !isVehicleDependent || !vehicle) {
                return
            }

            setIsLoading(true)

            const recommendedArticlesOut = await loadArticles(newArticle)

            if (recommendedArticlesOut?.length) {
                setRequest(listId, { articles: recommendedArticlesOut })
            }

            setIsLoading(false)
        },
        [enableRecommendedArticles, isVehicleDependent, loadArticles, setRequest, vehicle, setIsLoading]
    )

    const onClose = useCallback(() => {
        setLastArticleAddedToBasket(undefined)
        reset(listId)
    }, [reset, setLastArticleAddedToBasket])

    return useMemo(() => 
        ({ enabled, isLoading, loadRecommendedArticles, loadNextRecommendedArticle, onClose, ListComponent: PureListComponent }), 
        [enabled, isLoading, loadRecommendedArticles, loadNextRecommendedArticle, onClose, PureListComponent ]
    ) 
}
