import { ArticleInfoType, CatalogPart, CustomPart, OePart, Vehicle, WholesalerPart } from "@tm/models"
import { SendArticlesPostMessagePart } from "../models"

export const mapOePartToPostMessagePart = (part: OePart) => {
    const sendArticles: SendArticlesPostMessagePart = {
        articleType: ArticleInfoType.OeArticle,
        linkedItems: [],
        oeOrigin: part.oeArticleOrigin,
        dataSupplier: {
            id: part.vehicleManufacturerId,
            name: part.vehicleManufacturerName,
            dataPoolId: 0,
            tmfId: undefined,
        },
        quantity: part.quantityValue,
        vehicle: {
            manufacturerId: part.vehicleManufacturerId,
            dataPoolId: undefined,
            tmfId: undefined,
        },
        description: part.description,
    }

    return sendArticles
}

export const mapCustomPartToPostMessagePart = (part: CustomPart) => {
    const sendArticles: SendArticlesPostMessagePart = {
        articleType: ArticleInfoType.Undefined,
        quantity: part.quantityValue,
        articleNumber: part.articleNumber,
        description: part.description,
    }

    return sendArticles
}

export const mapCatalogpartToPostMessagePart = (part: CatalogPart, vehicle?: Vehicle) => {
    const sendArtciles: SendArticlesPostMessagePart = {
        articleType: ArticleInfoType.TecdocArticle,
        linkedItems:
            part.linkedItems?.map((linkedItem) => {
                return {
                    articleIdErp: linkedItem.wholesalerArticleNumber,
                    description: linkedItem.description,
                    additionalDescription: linkedItem.additionalDescription,
                }
            }) || [],
        dataSupplier: {
            id: part.dataSupplierId,
            name: part.dataSupplierName,
            dataPoolId: 0,
            tmfId: undefined,
        },
        productGroup: {
            id: part.productGroupId,
            name: part.productGroupName,
            dataPoolId: undefined,
            tmfId: undefined,
        },
        quantity: part.quantityValue,
        vehicle: {
            manufacturerId: vehicle?.tecDocManufacturerId,
            modelId: vehicle?.tecDocModelId,
            typeId: vehicle?.tecDocTypeId,
            dataPoolId: undefined,
            tmfId: undefined,
        },
        articleNumber: part.dataSupplierArticleNumber,
        articleIdErp: part.wholesalerArticleNumber,
        description: part.description,
        additionalDescription: part.additionalDescription,
    }

    return sendArtciles
}

export const mapWholsalerPartToPostMessagePart = (article: WholesalerPart, vehicle?: Vehicle) => {
    const sendArticles: SendArticlesPostMessagePart = {
        articleType: ArticleInfoType.WholesalerArticle,
        linkedItems: [],
        productGroup: {
            name: article.productGroupName,
            dataPoolId: undefined,
            tmfId: undefined,
        },
        quantity: article.quantityValue,
        vehicle: {
            manufacturerId: vehicle?.tecDocManufacturerId,
            modelId: vehicle?.tecDocModelId,
            typeId: vehicle?.tecDocTypeId,
            dataPoolId: undefined,
            tmfId: undefined,
        },
        additionalDescription: article.additionalDescription,
        description: article.description,
        articleNumber: article.wholesalerArticleNumber,
        articleIdErp: article.wholesalerArticleNumber,
    }

    return sendArticles
}
