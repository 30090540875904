import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { isDevtoolEnabled } from "@tm/utils"
import { createMainSlice, MainSlice } from "./main"
import { useQueueStore } from "./queue"

export type FastCalculatorStore = MainSlice

export const useFastCalculatorStore = create<FastCalculatorStore>()(
    devtools(
        (...a) => ({
            ...createMainSlice(...a),
        }),
        { name: "Fast Calculator store", enabled: isDevtoolEnabled() }
    )
)

export const { mainActions } = useFastCalculatorStore.getState()
export const { queueActions } = useQueueStore.getState()

export * from "./queue"
