import {
    CatalogPart,
    CustomPart,
    OePart,
    PostSendOrderAction,
    PostSendOrderActionTargetType,
    PostSendOrderActionType,
    WholesalerPart,
} from "@tm/models"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useCallback, useMemo } from "react"
import {
    mapCatalogpartToPostMessagePart,
    mapCustomPartToPostMessagePart,
    mapOePartToPostMessagePart,
    mapWholsalerPartToPostMessagePart,
} from "./mapper"
import { SendArticlesPostMessagePart as SendArticlesPostMessageArticles } from "../models"

const SOURCEID = "NEXT"

export function useCreateSendArticlesPostMessage() {
    const worktask = useWorkTask()
    const { userContext } = useUser()
    const vehicle = worktask?.workTask?.vehicle

    const externalSessionId = useMemo(() => {
        return userContext.externalAuthenticationTokens?.ExternalSessionId || ""
    }, [userContext.externalAuthenticationTokens])

    const getCreateSendArticlesPostMessage = useCallback(
        (articles: SendArticlesPostMessageArticles[], workTaskId: string): PostSendOrderAction[] => {
            return [
                {
                    action: PostSendOrderActionType.PostMessage,
                    target: PostSendOrderActionTargetType.ParentFrame,
                    value: JSON.stringify({
                        sourceId: SOURCEID,
                        externalSessionId,
                        sendArticles: {
                            workTaskId,
                            articles,
                        },
                    }),
                },
            ]
        },
        [externalSessionId]
    )

    const getMessageByWholsalerPart = useCallback(
        (parts: WholesalerPart[], workTaskId: string) => {
            const sendArticlesWholsalerParts = parts.map((part) => mapWholsalerPartToPostMessagePart(part, vehicle))
            return getCreateSendArticlesPostMessage(sendArticlesWholsalerParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage, vehicle]
    )

    const getMessageByCatalogPart = useCallback(
        (parts: CatalogPart[], workTaskId: string) => {
            const sendArticlesCatalogParts = parts.map((part) => mapCatalogpartToPostMessagePart(part, vehicle))
            return getCreateSendArticlesPostMessage(sendArticlesCatalogParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage, vehicle]
    )

    const getMessageByOePart = useCallback(
        (parts: OePart[], workTaskId: string) => {
            const sendArticlesByOeParts = parts.map((part) => mapOePartToPostMessagePart(part))
            return getCreateSendArticlesPostMessage(sendArticlesByOeParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage]
    )

    const getMessageByCustomPart = useCallback(
        (parts: CustomPart[], workTaskId: string) => {
            const sendArticlesParts = parts.map((part) => mapCustomPartToPostMessagePart(part))
            return getCreateSendArticlesPostMessage(sendArticlesParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage]
    )

    return { getMessageByCatalogPart, getMessageByCustomPart, getMessageByOePart, getMessageByWholsalerPart }
}
