import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Card, Headline, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { getCurrencyFromUserContext, renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { Article, ECalcOrigin, channel } from "@tm/models"
import { Badge, Box, Button, Divider, Icon, Loader, styled } from "@tm/components"
import { getComponentStyles } from "../_shared/styles"
import FastCalcSelection from "../_shared/modal/fastCalcSelection"
import { getLabourRate } from "../../helpers/userSettingsContextMethods"
import { createMemo, mapProductGroupIds } from "../../helpers"
import { createImportCalculationRequest } from "../../helpers/createImportCalculationRequest"
import { getBundleParams } from "../../utils"
import { stepNavigationActions, useFastServiceStore } from "../../data"
import { useBasketImports } from "../../../../basket/src/hooks/basketState/useBasketImports"
import { useRepairTimes } from "../../helpers/hooks/useRepairTimes"
import { PrintOptions, TopProductGroup } from "../../data/models"
import { useCostEstimationModuleParameter } from "../../../../basket/src/hooks/useCostEstimationModuleParameter"
import { SharePopover } from "./components/SharePopover"
import CustomDisplayOptionsModal from "../_shared/customDisplayOptionsModal"
import { usePdfDownload } from "../../helpers/hooks/usePdfDownload"
import { printMaintenance } from "./business/actions"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = RouteComponentProps<any>

const End: FC<Props> = ({ match }) => {
    const currentStepName = "end"
    const { hasCostEstimation } = useCostEstimationModuleParameter()
    const { translateText, languageId } = useLocalization()
    const { totalRepairTimesFormatted } = useRepairTimes()
    const { importFastCalculation } = useBasketImports()
    const { userSettings, userContext } = useUser()
    const { workTask } = useWorkTask() || {}
    const { downloadPdf } = usePdfDownload()
    const classNames = getComponentStyles()

    const { plateNumber, vin } = useFastServiceStore((state) => state.maintenancePlan.vehicleFields)
    const selectedItems = useFastServiceStore((state) => state.worksState.selectedItems)
    const workTimes = useFastServiceStore((state) => state.maintenancePlan.workTimes)
    const selectedArticles = useFastServiceStore((state) => state.selectedArticles)
    const calculationDone = useFastServiceStore((state) => state.calculationDone)
    const pdfUrlLoading = useFastServiceStore((state) => state.printLoading)
    const pdfExpiresAt = useFastServiceStore((state) => state.pdfExpiresAt)
    const printState = useFastServiceStore((state) => state.printOptions)
    const pdfUrlError = useFastServiceStore((state) => state.printError)
    const works = useFastServiceStore((state) => state.worksState.works)
    const pdfUrl = useFastServiceStore((state) => state.pdfUrl)

    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])
    const [showDisplayOptionsModal, setShowDisplayOptionsModal] = useState(false)
    const [isDownloadTriggered, setIsDownloadTriggered] = useState(false)
    const [showFastCalcModal, setShowFastCalcModal] = useState(false)
    const [isPrintTriggered, setIsPrintTriggered] = useState(false)

    useEffect(() => {
        stepNavigationActions.completeStep(currentStepName)

        const unsubscriptions: Array<() => void> = []
        const worktaskChannel = channel("WORKTASK", workTask?.id)
        unsubscriptions.push(
            worktaskChannel.subscribe("FAST-CALCULATOR/CALCULATION_DONE", (fastCalc) => {
                fastCalc.isFastCalcDone && useFastServiceStore.getState().setCalculationDone()
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    useEffect(() => {
        if (pdfUrlError) {
            setIsPrintTriggered(false)
            setIsDownloadTriggered(false)
        }
    }, [pdfUrlError])

    const handleRequestCalculation = useCallback((ids: number[], topProductGroups: TopProductGroup[]) => {
        if (!getBundleParams().calculatorRoute) {
            return
        }

        const route = renderRoute(getBundleParams().calculatorRoute!, {
            ...match.params,
            productGroupIds: mapProductGroupIds(topProductGroups),
            origin: ECalcOrigin.FastService,
        })

        handleToggleFastCalcModal()

        Morpheus.showView("1", route)
    }, [])

    const handleToggleFastCalcModal = () => {
        setShowFastCalcModal((prevState) => !prevState)
    }

    const handleToggleDisplayOptionsModal = () => {
        setShowDisplayOptionsModal((prevState) => !prevState)
    }

    const handlePrint = () => {
        setIsPrintTriggered(true)

        if (!pdfExpiresAt || Date.now() >= pdfExpiresAt) {
            printMaintenance(translateText, printState, userContext.account?.username ?? "", userSettings, workTask?.customer, () => {
                const printBtn = document.getElementById("print-btn")
                printBtn?.click()
                setIsPrintTriggered(false)
            })
        } else {
            const printBtn = document.getElementById("print-btn")
            printBtn?.click()
            setIsPrintTriggered(false)
        }
    }

    const handleDownloadPdf = async () => {
        setIsDownloadTriggered(true)

        if (!pdfExpiresAt || Date.now() >= pdfExpiresAt) {
            printMaintenance(translateText, printState, userContext.account?.username ?? "", userSettings, workTask?.customer, (downloadLink) => {
                downloadLink && downloadPdf(downloadLink, `${translateText(12790)} - ${plateNumber || vin || ""}`)
                setIsDownloadTriggered(false)
            })
        } else {
            pdfUrl && downloadPdf(pdfUrl, `${translateText(12790)} - ${plateNumber || vin || ""}`)
            setIsDownloadTriggered(false)
        }
    }

    const handleAddToCostEstimate = () => {
        if (!workTask?.id) {
            return
        }

        const memo = userSettings?.orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ? createMemo(workTask) : undefined

        const selectedRepairTimes = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) =>
                Object.values(value)
                    .map((x) => x.selectedRepairTimes!)
                    .flat()
                    .filter((x) => !!x)
            )
            ?.flat()

        const repairTimes = Object.values(workTimes).flat().concat(selectedRepairTimes)

        const consumables = Object.values(works)
            .flatMap((x) => x)
            .filter((y) => !!y.consumables.length && y.consumables.some((c) => c.article && c.isSelected))
            .map((z) => z.consumables)
            .flatMap((item) => item)

        const oeArticles = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) =>
                Object.values(value)
                    .filter((x) => x.selectedGenArts?.every((gA) => !gA.oeReplace))
                    .map((x) => x.selectedGenArts)
                    .flat()
                    .filter((x) => !!x)
            )
            ?.flat()

        const selectedItemsValues = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) => Object.values(value))
            .flat()

        const replacedArticles: Array<Article> = []

        selectedItemsValues.forEach((selectedItemsValue) => {
            selectedItemsValue.selectedGenArts?.forEach((genArt) => {
                if (genArt.oeReplace && genArt.article) {
                    replacedArticles.push(genArt.article)
                }
            })
        })

        const articles = [...replacedArticles, ...selectedArticles.filter((article) => article.supplier && article.productGroup)]

        const request = createImportCalculationRequest(
            articles,
            consumables,
            repairTimes,
            works,
            getLabourRate(userSettings),
            workTask,
            memo,
            currencyCode,
            oeArticles
        )

        if (!request) {
            return
        }

        importFastCalculation(request).then(
            () => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12998),
                    icon: "check",
                    skin: "success",
                    closeDelay: 3000,
                })
            },
            () => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12997),
                    icon: "error",
                    skin: "warning",
                    closeDelay: 3000,
                })
            }
        )
    }

    return (
        <>
            <StyledTextWrapper>
                <Headline size="m">{translateText(13030)}</Headline>
                <Text size="m">{translateText(13031)}</Text>
            </StyledTextWrapper>
            <CustomDisplayOptionsModal open={showDisplayOptionsModal} onOutsideClick={handleToggleDisplayOptionsModal} />
            <FastCalcSelection open={showFastCalcModal} onOutsideClick={handleToggleFastCalcModal} onCalculation={handleRequestCalculation} />
            <Scrollbar>
                <StyledBoxWrapper className={classNames.wrapperMargins}>
                    <StyledWrapper>
                        <StyledMainCard>
                            <StyledMainTitle size="xl">{translateText(13034)}</StyledMainTitle>
                            <Text size="m" modifiers="sub">
                                {translateText(13035)}
                            </Text>
                            <Button
                                className={classNames.buttonPadding}
                                size="extralarge"
                                startIcon={<Icon name="fast-calculator" />}
                                onClick={handleToggleFastCalcModal}
                            >
                                {translateText(1042)}
                            </Button>
                            {calculationDone && <StyledBadge badgeContent={<Icon color="success" name="check" size="xl" />} />}
                        </StyledMainCard>
                        {hasCostEstimation && (
                            <StyledMainCard>
                                <StyledMainTitle size="xl">{translateText(13687)}</StyledMainTitle>
                                <Text size="m" modifiers="sub">
                                    {translateText(12960)}
                                </Text>
                                <Button
                                    className={classNames.buttonPadding}
                                    size="extralarge"
                                    startIcon={<Icon name={languageId === "1" ? "voucher-kva" : "voucher-kva-international"} />}
                                    disabled={!totalRepairTimesFormatted}
                                    onClick={handleAddToCostEstimate}
                                >
                                    {translateText(1607)}
                                </Button>
                            </StyledMainCard>
                        )}
                        <StyledLastCard hasCostEstimation={hasCostEstimation}>
                            <StyledMainTitle size="xl">{translateText(13688)}</StyledMainTitle>
                            <Text size="m" modifiers="sub">
                                {translateText(12961)}
                            </Text>
                            <StyledInnerBox>
                                <StyledBox>
                                    <Button
                                        className={classNames.buttonPadding}
                                        size="extralarge"
                                        startIcon={<Icon name="print" />}
                                        onClick={handlePrint}
                                        disabled={isPrintTriggered}
                                    >
                                        {pdfUrlLoading && isPrintTriggered && <StyledLoader />}
                                        {translateText(13033)}
                                    </Button>
                                    <div id="print-btn" style={{ visibility: "hidden" }} onClick={() => window.open(pdfUrl, "_blank")} />
                                    <Button
                                        className={classNames.buttonPadding}
                                        size="extralarge"
                                        startIcon={<Icon name="download" />}
                                        onClick={handleDownloadPdf}
                                        disabled={isDownloadTriggered}
                                    >
                                        {pdfUrlLoading && isDownloadTriggered && <StyledLoader />}
                                        {translateText(13293)}
                                    </Button>
                                    <SharePopover />
                                </StyledBox>
                                <StyledDivider />
                                <Button
                                    sx={{ marginLeft: "auto", marginRight: "auto" }}
                                    size="medium"
                                    startIcon={<Icon name="settings" />}
                                    onClick={handleToggleDisplayOptionsModal}
                                    variant="outlined"
                                    disabled={isPrintTriggered || isDownloadTriggered}
                                >
                                    {translateText(844)}
                                </Button>
                            </StyledInnerBox>
                        </StyledLastCard>
                    </StyledWrapper>
                </StyledBoxWrapper>
            </Scrollbar>
        </>
    )
}

export default withRouter(End)

const StyledLoader = styled(Loader)({
    position: "absolute",
    top: "-50%",
    left: "50%",
    transform: "translate(-50%)",
})

const StyledInnerBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "1em",
    width: "100%",
})

const StyledDivider = styled(Divider)({
    width: "100%",
    border: "0",
    background: "#e0e0e0",
    height: "1px",
})

const StyledBadge = styled(Badge)(({ theme }) => ({
    position: "absolute",
    top: "1px",
    right: "25px",
    "& .MuiBadge-badge": {
        padding: ".25rem",
        background: `${theme.colors?.success}`,
        height: "unset",
        borderRadius: "1rem",
        borderColor: "white !important",
        border: "1px solid",
        "& .icon": {
            fill: "white !important",
        },
    },
}))

const StyledBox = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "0.7em",
})

const StyledWrapper = styled(Box)({
    display: "grid",
    gridTemplateColumns: "auto auto",
})

const StyledMainCard = styled(Card)({
    ".card__inner": {
        display: "flex",
        flexDirection: "column",
        gap: "3em",
        padding: "3em",
        alignItems: "baseline",
        justifyContent: "space-between",
        height: "100%",
    },
})

const StyledMainTitle = styled(Text)({
    fontWeight: 600,
})

const StyledTextWrapper = styled(Box)({
    display: "flex",
    padding: "1em 1em 2em 2em",
    gap: "0.5em",
    flexDirection: "column",
})

const StyledBoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
})

const StyledLastCard = styled(StyledMainCard)<{ hasCostEstimation: boolean }>((props) => ({
    ...(props.hasCostEstimation && {
        gridColumn: "span 2",
        justifySelf: "center",
    }),
    "& .card__inner": {
        padding: "2em 3em 1em 3em !important",
    },
}))
