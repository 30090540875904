import { Dialog } from "@tm/components"
import { FastCalculator } from "@tm/data"
import Content from "./shared/Content"
import { mainActions, useFastCalculatorStore } from "../../../state"

type Props = {
    calcState: FastCalculator.CalcState
}

export default function SelectionDialog({ calcState }: Props) {
    const loading = useFastCalculatorStore((state) => state.main.loading)

    const handleClose = () => {
        mainActions.closeSelectionDialog(calcState.type)
    }

    return (
        <Dialog open onOutsideClick={handleClose} fullWidth maxWidth="md" position="top">
            <Content calcState={calcState} loading={loading} onClose={handleClose} />
        </Dialog>
    )
}
