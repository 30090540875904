import { useHistory } from "react-router"
import { useLocalization } from "@tm/localization"
import { PostSendOrderAction, PostSendOrderActionTargetType, PostSendOrderActionType } from "@tm/models"
import { createQueryString, encodeUniqueId, renderRoute } from "@tm/utils"

export function useHandlePostSendOrderAction() {
    const history = useHistory()
    const { translateText } = useLocalization()

    return React.useCallback(
        (workTaskId: string, postSendOrderActions?: PostSendOrderAction[]) => {
            postSendOrderActions?.forEach((postAction) => {
                switch (postAction.action) {
                    case PostSendOrderActionType.OpenExternalUrl: {
                        if (postAction.target === PostSendOrderActionTargetType.NewWindow) {
                            window.open(postAction.value, "_blank")
                        } else if (postAction.target === PostSendOrderActionTargetType.ModuleFrame) {
                            const queryParams = createQueryString({
                                url: postAction.value,
                                title: translateText(13846),
                            })
                            const route = `${renderRoute(`/:workTaskId/ext/`, { workTaskId })}${queryParams}`
                            history.push(route)
                        }
                        break
                    }
                    case PostSendOrderActionType.PostMessage: {
                        if (postAction.target === PostSendOrderActionTargetType.ParentFrame) {
                            window.parent.postMessage(JSON.parse(postAction.value), "*")
                        }
                        break
                    }
                    default:
                        break
                }
            })
        },
        [history]
    )
}
