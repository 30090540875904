import {
    RegisteredModels,
    CarModelDetailsResponse,
    Article,
    VehicleRecordsContainer,
    OrderVouchersShowSupplierArticleByVehicleRequest,
    OrderVouchersShowSupplierArticleByVehicleResponse,
    FastCalculation,
} from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { Container } from "@tm/nexus"
import { FastCalculator } from "@tm/data"
import { CalculationContext } from "./data/model"
import {
    createButtonInputRequest,
    createImportCalculationRequest,
    createSubmitInputRequest,
    createSelectionItemClickRequest,
    createInitCalculationRequest,
    createSubmitDropdownItemClickRequest,
    createInitialDataRequest,
    createCalcProductGroupsRequest,
    createAddPartFromArticleListRequest,
    createAddCustomPartToConsumableRequest,
} from "./components/main/business/helper"
import { ECalcArticle } from "./data/enums"
import {
    mapCalcArticleToArticleIdentifier,
    createArticleComparer,
    ArticleIdentifierExt,
    bundleChannel,
    getLastModelDetails,
    findServiceStatusOnServer,
} from "./data/helpers"
import { mapVehicleRecordArticleAddedToBasketEventRequest } from "./data/helpers/vehicle-records"
import { mainActions, queueActions, useFastCalculatorStore, useQueueStore } from "./state"

export async function handleButtonClick(
    calcButton: FastCalculator.CalcStateButton,
    languageId: string,
    importFastCalculation: (importFastCalculationRequest: FastCalculation.ImportFastCalculationRequest) => Promise<any>,
    defaultHourlyRate?: number,
    workTask?: WorkTaskInfo,
    showCostEstimation?: () => void,
    isOverlayCalcState?: boolean,
    changeFCRoute?: (calcStateType?: number) => void,
    memo?: string
) {
    const { selectedCalcState, selectedOverlayCalcState, vehicle, carModel, unsavedServices, calcOrigin } = useFastCalculatorStore.getState().main
    const { requestQueueId } = useQueueStore.getState().state
    const parentCalcState = isOverlayCalcState ? selectedOverlayCalcState : selectedCalcState
    if (!parentCalcState || !vehicle || !selectedCalcState) {
        return
    }

    if (
        unsavedServices?.length > 0 &&
        (calcButton.type === FastCalculator.ECalcButtonState.StartCalculation || calcButton.type === FastCalculator.ECalcButtonState.Submit)
    ) {
        await callService(
            FastCalculator.selectSelectionItem,
            createSelectionItemClickRequest(unsavedServices, parentCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin),
            undefined,
            true
        )

        mainActions.resetSelectedServices()
    }

    if (calcButton.type === FastCalculator.ECalcButtonState.Reset) {
        mainActions.resetSelectedServices()
    }

    if (calcButton.type === FastCalculator.ECalcButtonState.StartNewCalculation) {
        mainActions.setSkipReload(true)
        mainActions.resetSelectedServices()
    }

    callService(
        FastCalculator.clickButton,
        createButtonInputRequest(
            calcButton,
            parentCalcState,
            vehicle,
            vehicle.tecDocTypeId,
            selectedCalcState,
            languageId,
            calcOrigin,
            carModel,
            defaultHourlyRate,
            requestQueueId
        ),
        undefined,
        undefined,
        changeFCRoute
    ).then(() => {
        const newState = useFastCalculatorStore.getState().main

        if (
            newState.selectedDataCalcState?.type === FastCalculator.ECalcState.BasketNext ||
            newState.selectedDataCalcState?.type === FastCalculator.ECalcState.QuoteNext
        ) {
            const request = createImportCalculationRequest(newState.selectedDataCalcState, defaultHourlyRate, workTask, memo)
            if (request) {
                importFastCalculation(request).then(() => {
                    if (request.parts?.length && newState.vehicle) {
                        const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords)

                        request.parts.forEach((x) => {
                            const article = newState.additionalData.articles.find(
                                (y) =>
                                    y.productGroup.id == x.productGroupId &&
                                    y.supplier.id == x.dataSupplierId &&
                                    y.supplierArticleNo == x.dataSupplierArticleNumber
                            )
                            if (article) {
                                // TODO: this action should be extended to support multiple articles
                                container.action("articleAddedToBasket")(
                                    mapVehicleRecordArticleAddedToBasketEventRequest(newState.vehicle!.id, article)
                                )
                            }
                        })
                    }

                    if (newState.selectedDataCalcState?.type == FastCalculator.ECalcState.QuoteNext) {
                        showCostEstimation?.()
                    }
                })
            }
        }
    })
}

export function handleInputSubmit(calcInput: FastCalculator.CalcInput, changeFCRoute?: ((calcStateType?: number | undefined) => void) | undefined) {
    const { selectedCalcState, vehicle, calcOrigin } = useFastCalculatorStore.getState().main
    if (!selectedCalcState || !vehicle) {
        return
    }

    callService(
        FastCalculator.submitInput,
        createSubmitInputRequest(calcInput, selectedCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin),
        undefined,
        undefined,
        changeFCRoute
    )
}

export function handleSelectionItemClick(item: FastCalculator.CalcSelectionItem, isOverlayCalcState?: boolean, isFromDialog?: boolean) {
    const { selectedCalcState, selectedOverlayCalcState, vehicle, calcOrigin } = useFastCalculatorStore.getState().main
    const parentCalcState = isOverlayCalcState ? selectedOverlayCalcState : selectedCalcState
    if (!parentCalcState || !vehicle) {
        return
    }

    if (isFromDialog) {
        callService(
            FastCalculator.selectSelectionItem,
            createSelectionItemClickRequest([item], parentCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin),
            undefined,
            true
        )
        return
    }

    const existsInWS = findServiceStatusOnServer(isOverlayCalcState ? selectedOverlayCalcState : selectedCalcState, isOverlayCalcState, item.id)

    mainActions.addSelectedService(item, existsInWS)
}

export function initFastCalculator(
    fromWidget: boolean,
    calcId?: string,
    isWorkshop?: boolean,
    changeFCRoute?: (calcStateType?: number | undefined) => void | undefined
) {
    const { vehicle, vehicleIdUsed, calcOrigin } = useFastCalculatorStore.getState().main
    if (!vehicle) {
        return
    }

    if (vehicle.id != vehicleIdUsed) {
        loadCarModel(vehicle.tecDocTypeId)
    }

    callService(
        FastCalculator.initFastCalc,
        createInitCalculationRequest(vehicle, fromWidget, calcOrigin, calcId, isWorkshop),
        vehicle.tecDocTypeId,
        undefined,
        changeFCRoute
    )
}

export function initialData(languageId: string, defaultHourlyRate?: number, fromWidget = false, customerNo?: string) {
    const { vehicle, carModel, selectedCalcState, calcOrigin } = useFastCalculatorStore.getState().main
    if (!vehicle || !selectedCalcState) {
        return
    }

    callService(
        FastCalculator.submitInitialData,
        createInitialDataRequest(selectedCalcState, vehicle, languageId, calcOrigin, carModel, defaultHourlyRate, fromWidget, customerNo)
    )
}

export function startCalculationWithProductGroups(
    productGroupIds: Array<number>,
    languageId: string,
    defaultHourlyRate?: number,
    origin?: string,
    topProductGroups?: FastCalculator.TopProductGroup[],
    changeFCRoute?: (calcStateType?: number | undefined) => void | undefined
) {
    const { vehicle, carModel, calcOrigin } = useFastCalculatorStore.getState().main
    if (!vehicle) {
        return
    }

    let modelDetails

    if (!carModel) {
        modelDetails = getLastModelDetails()
    }

    callService(
        FastCalculator.calcProductGroups,
        createCalcProductGroupsRequest(productGroupIds, vehicle, languageId, calcOrigin, modelDetails, defaultHourlyRate, origin, topProductGroups),
        vehicle.tecDocTypeId,
        undefined,
        changeFCRoute
    )
}

export function loadCarModel(tecDocTypeId: number) {
    const { vehicle } = useFastCalculatorStore.getState().main
    if (!vehicle) {
        return
    }

    const container = Container.getInstance<CarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
    container
        .subscribe({ modelId: tecDocTypeId })
        .load()
        .then((response) => {
            mainActions.setDetailsLoaded(response, vehicle.id)
            bundleChannel().publish("SHARE_MODEL_DETAILS", response)
        })
}

export function handleDropdownItemClick(
    calcInput: FastCalculator.CalcInput,
    dropdownItem: FastCalculator.CalcDropDownItem,
    changeFCRoute?: () => void
) {
    const { selectedCalcState, vehicle, calcOrigin } = useFastCalculatorStore.getState().main
    if (!selectedCalcState || !vehicle) {
        return
    }

    callService(
        FastCalculator.submitDropdownItemClick,
        createSubmitDropdownItemClickRequest(calcInput, dropdownItem, selectedCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin)
    )

    mainActions.setSkipReload(true)

    changeFCRoute?.()
}

export function handleAddPartFromArticleList(
    article: FastCalculator.ArticleData,
    searchType: FastCalculator.ESearchLink,
    changeFCRoute?: ((calcStateType?: number | undefined) => void) | undefined
) {
    const { selectedCalcState, vehicle, calcOrigin } = useFastCalculatorStore.getState().main
    if (!selectedCalcState || !vehicle) {
        return
    }

    callService(
        FastCalculator.addPartFromArticleList,
        createAddPartFromArticleListRequest(article, searchType, selectedCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin),
        undefined,
        undefined,
        changeFCRoute
    )
}

export function handleAddCustomPartToConsumable(
    id: string,
    label: string,
    price: number,
    articleNumber?: string,
    fastCalculatorConsumableId?: string
) {
    const { selectedCalcState } = useFastCalculatorStore.getState().main
    if (!selectedCalcState) {
        return
    }

    callService(
        FastCalculator.addCustomPartToConsumable,
        createAddCustomPartToConsumableRequest(
            id,
            label,
            price,
            selectedCalcState.calcId,
            selectedCalcState.calcETag,
            fastCalculatorConsumableId,
            articleNumber
        )
    )
}

function callService<T>(
    repositoryFunction: (request: T) => Promise<FastCalculator.MappedFastCalculatorData>,
    request: T,
    tecDocTypeId?: number,
    noRefresh?: boolean,
    changeFCRoute?: (calcStateType?: number) => void
) {
    mainActions.setFastCalculatorLoading()

    return repositoryFunction(request).then(
        (response) => {
            if (response.isQueued) {
                queueActions.queueStart(response.requestQueueId, response.checkStatusUrl)
                changeFCRoute?.(response?.selectedCalcState?.type)
                return
            }

            if (response?.selectedCalcState?.type === FastCalculator.ECalcState.CalculationNext) {
                const context = response.selectedCalcState.context as CalculationContext | undefined

                // Refresh additional article data such as order history or vehicle records
                loadAdditionalArticleData()

                if (context?.articles?.genArts) {
                    const state = useFastCalculatorStore.getState().main
                    const articlesToLoad: Array<ArticleIdentifierExt> = []

                    // Gather articles which have to be loaded
                    context.articles.genArts.forEach((genArt) => {
                        if (!genArt.articles) {
                            return
                        }

                        genArt.articles.forEach((article) => {
                            // If it's an IAM article and the data is not already loaded, add it to the request
                            if (
                                (article.type == ECalcArticle.Article || article.type == ECalcArticle.AlternativeArticle) &&
                                !state.additionalData?.articles.some(createArticleComparer(article, genArt))
                            ) {
                                articlesToLoad.push(
                                    mapCalcArticleToArticleIdentifier(article, genArt.genArtNr, tecDocTypeId ?? state.vehicle?.tecDocTypeId)
                                )
                            }

                            // Load articles of the oe groups (to be able to show the vehicle records count for this oe group)
                            article.oeGroups?.forEach((oeGroup) => {
                                const alternativeArticle = oeGroup.value2
                                // If this oe group has a second value which is an article,
                                // the type of the attached article is IAM (no oe article or product group)
                                // and the data is not already loaded, add it to the request
                                if (
                                    alternativeArticle &&
                                    alternativeArticle.supplierId &&
                                    alternativeArticle.supplierArtNr &&
                                    oeGroup.value2Type == FastCalculator.ECalcInputValue.Article &&
                                    (alternativeArticle.type == ECalcArticle.Article || alternativeArticle.type == ECalcArticle.AlternativeArticle) &&
                                    !state.additionalData?.articles.some(createArticleComparer(alternativeArticle, genArt))
                                ) {
                                    articlesToLoad.push(
                                        mapCalcArticleToArticleIdentifier(
                                            alternativeArticle,
                                            genArt.genArtNr,
                                            tecDocTypeId ?? state.vehicle?.tecDocTypeId
                                        )
                                    )
                                }
                            })
                        })
                    })

                    // Load article data (to use it for the parts item micro)
                    loadArticles(articlesToLoad)
                }
            } else if (response?.selectedCalcState?.type == FastCalculator.ECalcState.InitialData) {
                mainActions.resetAdditionalData()
            }

            mainActions.fastCalculatorLoaded(response)

            changeFCRoute?.(response?.selectedCalcState?.type)

            return response
        },
        (e) => {
            mainActions.setFastCalculatorError()
            throw e
        }
    )
}

export function loadArticles(articlesToLoad: Array<ArticleIdentifierExt>) {
    if (!articlesToLoad.length) {
        return
    }

    mainActions.onArticlesLoading()

    Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByArticleNumbersWithOptionalVehicle)
        .subscribe(articlesToLoad)
        .load()
        .then((articles) => {
            if (!articles?.length) {
                mainActions.onArticlesEmptyResponse()
                return
            }

            // Copy some data from the fast calculator response to the articles
            articles.forEach((x) => {
                const relatedRequestItem = articlesToLoad.find(
                    (y) =>
                        y.productGroupId == x.productGroup.id &&
                        y.supplierId == x.supplier.id &&
                        y.supplierArticleNo == x.supplierArticleNo &&
                        y.internalId == x.internalId
                )
                if (relatedRequestItem) {
                    x.fittingSide = x.fittingSide ?? relatedRequestItem.fittingPosition
                }
            })

            loadAdditionalArticleData(articles) // Load article related data necessary for newly loaded articles
            mainActions.onArticlesLoaded(articles)
        })
        .catch(() => mainActions.onArticlesLoaded([]))
}

export function loadAdditionalArticleData(articles?: Array<Article>) {
    const state = useFastCalculatorStore.getState().main

    // If articles were supplied load additonal data for them
    // and append it to the existing data at the state (appendData = true)
    // Otherwise load additional data for all and replace it (appendData = false)
    const appendData = !!articles
    articles = articles ?? state.additionalData?.articles

    if (!state.vehicle || !articles || !articles.length) {
        return
    }

    // Load order history
    const orderInfoRequest: OrderVouchersShowSupplierArticleByVehicleRequest = {
        vehicleId: state.vehicle.id,
        productGroupFilterIds: articles.map((x) => x.productGroup.id).join(","),
    }
    Container.getInstance<OrderVouchersShowSupplierArticleByVehicleResponse>(RegisteredModels.Vouchers_ShowSupplierArticleByVehicle)
        .subscribe(orderInfoRequest)
        .load()
        .then((response) => {
            if (response?.orderedSupplierArticles?.length) {
                mainActions.orderHistoryLoaded(response.orderedSupplierArticles, appendData)
            }
        })
}

export type IBundleActions = typeof BundleActions

export const BundleActions = {
    initFastCalculator,
    handleButtonClick,
    handleInputSubmit,
    handleSelectionItemClick,
    loadCarModel,
    handleDropdownItemClick,
    handleAddPartFromArticleList,
    handleAddCustomPartToConsumable,
    initialData,
    startCalculationWithProductGroups,
}
