import { EReplaceButtonBundle, channel } from "@tm/models"
import { useCallback, useEffect } from "react"
import { useHistory } from "react-router"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { replaceArticleCancel, replaceArticleFinish } from "../moduleManager/business"

export function Manager() {
    const history = useHistory()
    const { languageId } = useLocalization()
    const { userSettings } = useUser() ?? {}

    const handleReplaceCallback = useCallback(
        ({ part: replacePart, bundle, cancelAction }) => {
            if (bundle !== EReplaceButtonBundle.FastCalculatorNext) {
                return false
            }

            if (cancelAction) {
                replaceArticleCancel({ history, languageId, userSettings })
                return
            }

            replaceArticleFinish({ replacePart, history })
        },
        [history]
    )

    useEffect(() => {
        const unsub = channel("GLOBAL").clearAndSubscribe("PARTS/REPLACE_PART", handleReplaceCallback)

        return unsub
    }, [handleReplaceCallback])

    return null
}
