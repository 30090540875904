import { FC, memo, useMemo, useState } from "react"
import { Button, Dropdown } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FastCalculator } from "@tm/data"
import { getButtonSkin } from "../../../data/helpers"
import { handleDropdownItemClick } from "../../../business"

type Props = {
    calcInput: FastCalculator.CalcInput
    changeFCRoute?: () => void
    changeDropDownPosition?: boolean
}

export default function CalcInputDropdown({ calcInput, changeFCRoute, changeDropDownPosition }: Props) {
    const { translateText } = useLocalization()
    const [selectedDropDown, setSelectedDropDown] = useState<DropDownItem>()

    const dropDownItems = useMemo(() => {
        const mappedItems = mapDropDownItems(calcInput.dropDownItems)
        if (calcInput.type === FastCalculator.ECalcInputType.History) {
            mappedItems.unshift({ value: translateText(1039), index: -1, disabled: false })
        }

        return mappedItems
    }, [calcInput])

    const handleClick = () => {
        const calcDropDownItem = calcInput.dropDownItems.find((x) => x.label === selectedDropDown?.value)
        if (calcDropDownItem) {
            handleDropdownItemClick(calcInput, calcDropDownItem, changeFCRoute || undefined)
            changeFCRoute?.()
        }
    }

    const handleDropDownChange = (dropdownItem: DropDownItem) => {
        const calcDropDownItem = calcInput.dropDownItems.find((x) => x.value == dropdownItem.value || x.label == dropdownItem.value)
        setSelectedDropDown(dropdownItem)
        if (calcInput.type !== FastCalculator.ECalcInputType.History && calcDropDownItem) {
            handleDropdownItemClick(calcInput, calcDropDownItem, changeFCRoute || undefined)
        }
    }

    return (
        <>
            <Dropdown
                className="dropdown-fast-calc"
                alignArrow={(changeDropDownPosition && "bottom") || undefined}
                items={dropDownItems}
                amountItemsToShow={5}
                itemView={DropDownItemView}
                value={selectedDropDown || dropDownItems.first()}
                onChange={handleDropDownChange}
            />
            {calcInput.type === FastCalculator.ECalcInputType.History && (
                <Button
                    style={{ marginLeft: ".25em" }}
                    disabled={selectedDropDown?.disabled || selectedDropDown?.index === -1 || !selectedDropDown}
                    onClick={handleClick}
                    skin={getButtonSkin(calcInput.btnStyle)}
                >
                    {translateText(1579)}
                </Button>
            )}
        </>
    )
}

const DropDownItemView: FC<DropDownItem> = memo(({ value }) => {
    return <div style={{ textAlign: "center" }}>{value}</div>
})

type DropDownItem = {
    value: string
    index: number
    disabled: boolean
}

function mapDropDownItems(items?: FastCalculator.CalcDropDownItem[]): DropDownItem[] {
    if (!items) {
        return []
    }
    return items
        .sort((a, b) => (a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1))
        .map((item, index) => ({ value: item.label, index, disabled: !!item.isDisabled }))
}
