import { FastCalculator } from "@tm/data"
import Categories from "./components/categories"
import VehicleDefinition from "./components/vehicleDefinition"
import Header from "./components/header"
import SelectionDialog from "../_shared/modals/SelectionDialog"
import { useFastCalculatorStore } from "../../state"

export default function Summary() {
    const selectedOverlayCalcState = useFastCalculatorStore((state) => state.main.selectedOverlayCalcState)
    const interactionDialog = selectedOverlayCalcState && FastCalculator.ECalcState.OverlayCalcStates & selectedOverlayCalcState?.type

    return (
        <div className="fastcalc-summary">
            <Header />
            <VehicleDefinition />
            {interactionDialog && <SelectionDialog calcState={selectedOverlayCalcState} />}
            <Categories maxSelections={6} />
        </div>
    )
}
