import { useCallback, useEffect, useState } from "react"
import { isDateValid, isSameDay } from "../../../../helpers"

export function useDateFilter(savedDate: Date | null, saveFunction: (date: Date | null) => void, minDate: Date) {
    const [selectedDate, setSelectedDate] = useState<Date | null>(savedDate)

    useEffect(() => {
        setSelectedDate((state) => (isSameDay(state, savedDate) ? state : savedDate))
    }, [savedDate])

    const handleChange = useCallback(
        (date: Date | null) => {
            // Manually entering the date by typing would result in a valid date even only one digit for the year was entered.
            // But we have to ignore that to allow the user to enter the full date.
            if (date && (Number.isNaN(date.getTime()) || date.getFullYear() < 1000)) {
                return
            }

            setSelectedDate((state) => (isSameDay(state, date) ? state : date))

            if (isDateValid(date, minDate) && !isSameDay(savedDate, date)) {
                saveFunction(date)
            }
        },
        [savedDate, saveFunction, minDate]
    )

    return {
        selectedDate,
        handleChange,
    }
}
