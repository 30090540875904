import { em, margin, percent, rem } from "csx"
import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { bem, contextSelector, deepResultSelector } from "../../data/helpers"
import { CalcPrice } from "../../data/model"

type Props = {
    isHeader?: boolean
}

export default function Prices({ isHeader }: Props) {
    const { currency, translateText } = useLocalization()
    const { articlePrice, repairTimesPrice, totalPrice, totalPriceVat } = deepResultSelector()
    const { currencySign, hasInvalidArticles, hasInvalidRepairTimes, hasInvalidConsumabled } = contextSelector()
    const style = useMemo(() => getStyle(), [])

    const renderPanel = (calcPrice: CalcPrice, isTotal?: boolean, invalid?: boolean, text?: string) => {
        let label = text || (calcPrice?.label ?? "")
        if (!isTotal) {
            label = `${calcPrice?.totalQuantity ?? 0} ${label}`
        }

        return (
            <div className={bem(style.pricePanel, isHeader && "is-navigation")}>
                <Text size="s" modifiers={["sub"]} className={bem(style.text, isHeader && "is-navigation")}>
                    {label}
                </Text>
                <div className={style.priceWrapper}>
                    <Text
                        className={bem(style.priceValue, isTotal && "highlight", isHeader && "is-navigation", invalid && "invalid")}
                        size="xl"
                        modifiers="strong"
                    >
                        {currency(calcPrice?.value ?? 0, currencySign)}
                    </Text>
                    {text && (
                        <Text className={style.smallText} size="s">
                            {`${translateText(694)} ${currency(totalPrice?.value ?? 0, currencySign)}`}
                        </Text>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={bem(style.wrapper, isHeader && "is-navigation")}>
            {articlePrice && renderPanel(articlePrice, false, hasInvalidArticles || hasInvalidConsumabled)}
            {repairTimesPrice && renderPanel(repairTimesPrice, false, hasInvalidRepairTimes)}
            {totalPriceVat && renderPanel(totalPriceVat, true, undefined, translateText(62))}
            {/* {totalPrice && renderPanel(totalPrice, true)} */}
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            display: "flex",
            padding: "1.5em 8em 0 8em",
            $nest: {
                "&--is-navigation": {
                    padding: 0,
                },
            },
        },
        pricePanel: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            margin: "0 1.5em",
            position: "relative",
            $nest: {
                "&--is-navigation": {
                    margin: margin(0, theme.margin.s),
                },
            },
        },
        text: {
            margin: margin(0, theme.margin.m, theme.margin.m, theme.margin.m),
            textTransform: "uppercase",
            $nest: {
                "&--is-navigation": {
                    fontSize: em(1),
                    margin: margin(0, theme.margin.s, theme.margin.s, theme.margin.s),
                },
            },
        },
        priceWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        smallText: {
            alignSelf: "center",
        },
        priceValue: {
            display: "flex",
            justifyContent: "center",
            fontSize: rem(1.5),
            margin: margin(0, theme.margin.m, theme.margin.m, theme.margin.m),
            $nest: {
                "&--is-navigation": {
                    fontSize: em(1.2),
                    margin: margin(0, theme.margin.s, theme.margin.s, theme.margin.s),
                },
                "&::before": {
                    content: `""`,
                    position: "absolute",
                    left: 0,
                    height: em(0.15),
                    width: percent(100),
                    bottom: 0,
                    backgroundColor: "#c3c3c3",
                    borderRadius: theme.box.radius,
                },
                "&--highlight": {
                    color: theme.colors.highlight,
                },
                "&--invalid::before": {
                    backgroundColor: theme.colors.danger,
                },
            },
        },
    })(Prices)
}
