import { StateCreator } from "zustand"
import { FastCalculator } from "@tm/data"
import { Article, CarModelDetailsResponse, ECalcOrigin, OrderVoucherSupplierItem, Vehicle } from "@tm/models"
import { MainSlice, MainState } from "./model"
import { FastCalculatorStore, queueActions } from ".."
import { ReplaceArticle, Services, SummaryContext } from "../../data/model"
import { useQueueStore } from "../queue"
import { handleChecboxToggles } from "../../components/moduleManager/business"
import { getSelectedServicesFromWS } from "../../data/helpers"

const initialState: MainState = {
    main: {
        loading: true,
        error: false,
        skipReload: false,
        calcOrigin: ECalcOrigin.Next,
        unsavedServices: [],
        selectedServices: [],
        additionalData: {
            loading: false,
            articles: [],
            orderHistory: [],
        },
        fastCalculatorData: {
            activeCalcState: FastCalculator.ECalcState.None,
            availableCalcStates: FastCalculator.ECalcState.None,
            calcStates: [],
        },
    },
}

export const createMainSlice: StateCreator<FastCalculatorStore, [["zustand/devtools", never]], [], MainSlice> = (set) => ({
    ...initialState,
    mainActions: {
        setSkipReload: (skip) => set((state) => setSkipReload(state, skip)),
        resetArticleReplace: () => set((state) => resetArticleReplace(state)),
        startArticleReplace: (replaceArticle) => set((state) => startArticleReplace(state, replaceArticle)),
        setSelectedCalcSelection: (selectedCalcSelection: FastCalculator.CalcSelection) =>
            set((state) => setSelectedCalcSelection(state, selectedCalcSelection)),
        setCalcOrigin: (calcOrigin) => set((state) => setCalcOrigin(state, calcOrigin)),
        resetSelectedServices: () => set((state) => resetSelectedServices(state)),
        addSelectedService: (item: FastCalculator.CalcSelectionItem, existsInWS?: boolean) =>
            set((state) => addSelectedService(state, item, existsInWS)),
        orderHistoryLoaded: (orderHistory, appendData) => set((state) => orderHistoryLoaded(state, orderHistory, appendData)),
        onArticlesLoaded: (articles) => set((state) => onArticlesLoaded(state, articles)),
        onArticlesEmptyResponse: () => set((state) => onArticlesEmptyResponse(state)),
        onArticlesLoading: () => set((state) => onArticlesLoading(state)),
        resetAdditionalData: () => set((state) => resetAdditionalData(state)),
        closeSelectionDialog: (overlayCalcStateType) => set((state) => closeSelectionDialog(state, overlayCalcStateType)),
        setVehicle: (vehicle) => set((state) => setVehicle(state, vehicle)),
        setFastCalculatorError: () => set((state) => setFastCalculatorError(state)),
        setFastCalculatorLoading: () => set((state) => setFastCalculatorLoading(state)),
        setConfigProps: (costEstimationUrl, inModal) => set((state) => setConfigProps(state, costEstimationUrl, inModal)),
        setDetailsLoaded: (modelDetails, vehicleId) => set((state) => setDetailsLoaded(state, modelDetails, vehicleId)),
        fastCalculatorLoaded: (data) => set((state) => fastCalculatorLoaded(state, data)),
    },
    resetStore: () => set(reset, false, "Reset Main Slice"),
})

function reset(): Partial<MainSlice> {
    return {
        ...initialState,
    }
}

function fastCalculatorLoaded(state: FastCalculatorStore, data: FastCalculator.MappedFastCalculatorData): Partial<FastCalculatorStore> {
    const {
        selectedCalcState,
        fastCalculatorData,
        selectedDataCalcState,
        selectedDialogCalcState,
        selectedOverlayCalcState,
        alternativeCalcArticles,
    } = data

    const initialRegistration = selectedCalcState?.context?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.RegDate)?.value as string
    const engineCode = selectedCalcState?.context?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.EngineCode)?.value as string
    const mileage = selectedCalcState?.context?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.Mileage)?.value as number | undefined
    const longLife = selectedCalcState?.context?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.Longlife)?.value

    const selectedServicesFromWS = getSelectedServicesFromWS(selectedCalcState?.context as SummaryContext)
    const unsavedDeselections = state.main.unsavedServices.filter((s) => s.isSelected === true)
    const unsavedSelections = state.main.unsavedServices.filter((s) => s.isSelected === false)
    const serviceList: FastCalculator.CalcSelectionItem[] = []

    selectedServicesFromWS.forEach((service) => {
        const contains = unsavedDeselections.filter((s) => s.id === service.id)
        if (contains.length === 0) {
            serviceList.push(service)
        }
    })

    queueActions.queueCancelled()

    return {
        main: {
            ...state.main,
            loading: false,
            selectedCalcState,
            selectedDataCalcState,
            selectedDialogCalcState,
            fastCalculatorData,
            selectedOverlayCalcState,
            alternativeCalcArticles,
            error: false,
            engineCode,
            initialRegistration: (initialRegistration && new Date(initialRegistration)) || undefined,
            mileage,
            longLife,
            selectedServices: serviceList.length > 0 ? serviceList.concat(unsavedSelections) : unsavedSelections,
        },
    }
}

function setDetailsLoaded(state: FastCalculatorStore, modelDetails: CarModelDetailsResponse, vehicleId: string): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            carModel: modelDetails,
            vehicleIdUsed: vehicleId,
        },
    }
}

function setConfigProps(state: FastCalculatorStore, costEstimationUrl: string, inModal?: boolean): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            costEstimationUrl,
            inModal,
        },
    }
}

function setFastCalculatorLoading(state: FastCalculatorStore): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            loading: true,
        },
    }
}

function setFastCalculatorError(state: FastCalculatorStore): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            error: true,
            loading: false,
        },
    }
}

function setVehicle(state: FastCalculatorStore, vehicle: Vehicle): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            vehicle,
        },
    }
}

function closeSelectionDialog(state: FastCalculatorStore, overlayCalcStateType: FastCalculator.ECalcState): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            selectedOverlayCalcState: undefined,
        },
    }
}

function resetAdditionalData(state: FastCalculatorStore): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            additionalData: {
                loading: false,
                articles: [],
                orderHistory: [],
            },
        },
    }
}

function onArticlesLoading(state: FastCalculatorStore): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            additionalData: {
                ...state.main.additionalData,
                loading: true,
            },
        },
    }
}

function onArticlesEmptyResponse(state: FastCalculatorStore): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            additionalData: {
                ...state.main.additionalData,
                loading: false,
            },
        },
    }
}

function onArticlesLoaded(state: FastCalculatorStore, articles: Array<Article>): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            additionalData: {
                ...state.main.additionalData,
                loading: false,
                articles: [...state.main.additionalData.articles, ...articles],
            },
        },
    }
}

function orderHistoryLoaded(
    state: FastCalculatorStore,
    orderHistory: Array<OrderVoucherSupplierItem>,
    appendData: boolean
): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            additionalData: {
                ...state.main.additionalData,
                orderHistory: !appendData ? orderHistory : state.main.additionalData.orderHistory.concat(orderHistory),
            },
        },
    }
}

function addSelectedService(state: FastCalculatorStore, item: FastCalculator.CalcSelectionItem, existsInWS?: boolean): Partial<FastCalculatorStore> {
    const validService: Services = {
        selectedServices: [...state.main.selectedServices],
        unsavedServices: [...state.main.unsavedServices],
    }
    const newService = item

    const calcType = state.main.selectedCalcState?.type
    if (calcType === FastCalculator.ECalcState.FastCockpitNext || calcType === FastCalculator.ECalcState.CalculationNext) {
        handleChecboxToggles(validService, newService, existsInWS)
    }

    return {
        main: {
            ...state.main,
            unsavedServices: validService.unsavedServices,
            selectedServices: validService.selectedServices,
        },
    }
}

function resetSelectedServices(state: FastCalculatorStore): Partial<FastCalculatorStore> {
    useQueueStore.getState().queueActions.queueCancelled()

    return {
        main: {
            ...state.main,
            selectedServices: [],
            unsavedServices: [],
        },
    }
}

function setCalcOrigin(state: FastCalculatorStore, calcOrigin: ECalcOrigin): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            calcOrigin,
        },
    }
}

function setSelectedCalcSelection(state: FastCalculatorStore, selectedCalcSelection: FastCalculator.CalcSelection): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            selectedCalcSelection,
        },
    }
}

function startArticleReplace(state: FastCalculatorStore, replaceArticle: ReplaceArticle): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            replaceArticle,
        },
    }
}

function resetArticleReplace(state: FastCalculatorStore): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            replaceArticle: undefined,
        },
    }
}

function setSkipReload(state: FastCalculatorStore, skipReload: boolean): Partial<FastCalculatorStore> {
    return {
        main: {
            ...state.main,
            skipReload,
        },
    }
}
